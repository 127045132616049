import React from "react";
import LogoRed from "../img/logoRed.png";
import { Link } from "react-router-dom";

const NavbarExhibitors = () => {
  return (
    <>
      <nav className='position-sticky top-0'>
        <div className='container'>
          <div className='row d-flex align-items-center'>
            <div className='col-10 d-flex align-items-center justify-content-start gap-3'>
              <img src={`${LogoRed}`} alt='' className='logo-red' />

              <input
                type='text'
                className='form-control w-75 text-start'
                placeholder='Cerca tra espositori, prodotti, settori e storie'
              />
            </div>

            <div className='col'>
              <a className='btn btn-secondary text-white text-uppercase'>
                Accedi o registrati
              </a>
            </div>
          </div>
          <div className='row d-flex align-items-center my-3'>
            <div className='gap-3 d-flex col-6 justify-content-start text-start text-capitalize link-pages'>
              <Link to='/' className='text-decoration-none text-dark'>
                home
              </Link>
              <Link to='/exhibitors' className='text-decoration-none text-dark'>
                espositori
              </Link>
              <a>settori</a>
              <a>prodotti</a>
              <a>storie</a>
              <a>eventi</a>
              <a>social flow</a>
            </div>
            <div className='col-6 d-flex justify-content-end gap-3 link-pages'>
              <a>Torna al sito</a>
              <a>
                IT <i className='bi bi-chevron-down'></i>
              </a>
            </div>
          </div>
        </div>
      </nav>
      <hr />
    </>
  );
};

export default NavbarExhibitors;
