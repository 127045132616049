import React from "react";
import LogoTuttoFood from "../img/logo-white_60ee9ca720c86.png";
import { Link } from "react-router-dom";

const NavbarRegistration = () => {
  return (
    <React.Fragment>
      <nav className=' d-flex text-white align-items-center justify-content-around'>
        <div className='container'>
          <div className='row d-flex align-items-center'>
            <div className='col-6 d-flex '>
              <img src={`${LogoTuttoFood}`} alt='' style={{ width: "12vw" }} />
            </div>
            <div className='col-6 d-flex justify-content-end'>
              <a className='btn btn-secondary text-white text-uppercase'>
                Accedi o registrati
              </a>
            </div>
          </div>
          <div className='row d-flex align-items-center my-3'>
            <div className='gap-3 d-flex col-6 justify-content-start text-start text-capitalize link-pages'>
              <Link to='/' className='text-decoration-none text-white'>
                homepage
              </Link>
              <Link
                to='/exhibitors'
                className='text-decoration-none text-white'
              >
                espositori
              </Link>
              <a>settori</a>
              <a>prodotti</a>
              <a>storie</a>
              <a>eventi</a>
              <a>social flow</a>
            </div>
            <div className='col-6 d-flex justify-content-end gap-3 link-pages'>
              <a>Torna al sito</a>
              <Link
                to='test-modal-chatbot'
                className='text-white text-decoration-none'
              >
                IT <i className='bi bi-chevron-down'></i>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default NavbarRegistration;
