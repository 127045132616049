import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import NavbarRegistration from "./components/NavbarRegistration";
import Homepage from "./components/Homepage";
import CategoriesHome from "./components/CategoriesHome";
import Exhibitors from "./components/Exhibitors";
import ExhibitorSingle from "./components/ExhibitorSingle";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TestModalChatbot from "./components/TestModalChatbot";

function App() {
  return (
    <Routes>
      <Route path='/' element={<Homepage />} />
      <Route path='/exhibitors' element={<Exhibitors />} />
      <Route path='/exhibitor/:id' element={<ExhibitorSingle />} />
      <Route path='/test-modal-chatbot' element={<TestModalChatbot />} />
    </Routes>
  );
}

export default App;
