import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import axios from "axios";
import ExhibitorsList from "./ExhibitorsList";
import ExhibitorDetail from "./ExhibitorDetail";
import MacchinaDaScrivere from "./MacchinaDaScrivere";
import { useInView } from "react-intersection-observer";
import NavbarRegistration from "./NavbarRegistration";
import $ from "jquery";
import CategoriesHome from "./CategoriesHome";

const Homepage = ({ content }) => {
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [response, setResponse] = useState("");
  const [jsonRasa, setJsonRasa] = useState("");
  const [modelData, setModelData] = useState("");
  const [informationIntent, setInformationIntent] = useState("");
  const [rispostaAlt, setRispostaAlt] = useState("");
  const [mostraInfo, setMostraInfo] = useState(false);
  const [followingIntent, setFollowingIntent] = useState(null);
  const [passEntities, setPassEntities] = useState(true);

  //Classificazione intent
  const [intentClassification, setIntentClassification] = useState("");

  // Inizializza lo stato iniziale della sessione.
  const [sessionId, setSessionId] = useState(null);

  // Genera un nuovo ID di sessione quando l'utente accede alla chatbot per la prima volta.
  useEffect(() => {
    if (!sessionId) {
      const newSessionId = generateSessionId();
      setSessionId(newSessionId);
      localStorage.setItem("sessionId", newSessionId);
    }
  }, [sessionId]);

  const err502message =
    "Ci scusiamo per l'inconveniente. Il server sta attualmente riscontrando un errore 502 e stiamo lavorando per risolvere il problema il prima possibile. Ti preghiamo di riprovare più tardi. Grazie per la tua pazienza e comprensione.";

  // Rimuovi l'ID di sessione dal localStorage quando l'utente effettua il refresh della pagina.
  function handleBeforeUnload() {
    localStorage.removeItem("sessionId");
  }

  const scrollRef = useRef(null);

  useEffect(() => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  }, [informationIntent]);

  // Registra l'evento beforeunload per gestire il refresh della pagina.
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Genera un nuovo ID di sessione.
  function generateSessionId() {
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 100000);
    return `${timestamp}-${random}`;
  }

  // const apiModel = "https://cognitivesearch.fulgeas.com/api/get-model/abcdef";
  // Effettua una richiesta sincrona all'API al momento del rendering del componente
  // Effettuo

  //   useEffect(() => {
  //     axios
  //       .post(apiModel, {
  //         headers: {
  //           "Content-Type": "application/yml",
  //         },
  //       })
  //       .then((res) => {
  //         setModelData(res.data);
  //       })
  //       .catch((err) => console.log(err));
  //   }, []);

  //   const urlTrain = "http://localhost:5005/model/train";

  //   const TrainTheModel = () => {
  //     const config = {
  //       headers: { "Content-Type": "application/yml" },
  //       PayLoad: `
  // ${modelData}
  // `,
  //     };

  //     axios
  //       .post(urlTrain, config.PayLoad, {
  //         headers: config.headers,
  //       })

  //       .then((res) => {
  //         // console.log(res);
  //       })
  //       .catch((err) => console.log(err));
  //   };

  const [clicked, setIsClicked] = useState(false);

  console.log(chatHistory);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setIsClicked(true);
      handleSendMessage();
    }
  };

  const [lastMessage, setLastMessage] = useState("");

  const handleSendMessage = async () => {
    setIsClicked(false);
    if (message.trim() === "") return;

    // https://engine.cognitivesearch.fulgeas.com/model/parse

    // http://localhost:5005/model/parse

    //https://cognitivesearch.fulgeas.com/api/get-model/abcdef

    try {
      const rasaResponse = await fetch(
        "https://engine.cognitivesearch.fulgeas.com/model/parse",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ text: message }),
        }
      );

      if (rasaResponse.status === 502) {
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          {
            userMessage: message,
            systemResponse: [
              {
                bot_text: err502message,
              },
            ],
          },
        ]);
        setMessage("");
      }

      const rasaData = await rasaResponse.json();
      setJsonRasa(JSON.stringify(rasaData, null, 2));
      setInformationIntent(rasaData);

      setLastMessage(message);
      setMessage("");
      setButtonClicked(true);
    } catch (error) {
      if (error instanceof TypeError) {
        console.log("Errore di rete:", error);
        // gestire l'errore di rete qui
      } else {
        console.log(error);
        setResponse("Mi dispiace, c'è stato un errore");
      }
    }
  };

  const ButtonNavigation = async (message) => {
    let initalMessage = message.split(";");

    console.log(initalMessage[0]);
    if (message.trim() === "") return;

    // https://engine.cognitivesearch.fulgeas.com/model/parse

    // http://localhost:5005/model/parse

    //https://cognitivesearch.fulgeas.com/api/get-model/abcdef

    console.log(systemResponse);

    try {
      const rasaResponse = await fetch(
        "https://engine.cognitivesearch.fulgeas.com/model/parse",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            text: initalMessage[0],
          }),
        }
      );

      if (rasaResponse.status === 502) {
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          {
            userMessage: initalMessage[0],
            systemResponse: [
              {
                bot_text: err502message,
              },
            ],
          },
        ]);
        setMessage("");
      }

      const rasaData = await rasaResponse.json();
      setJsonRasa(JSON.stringify(rasaData, null, 2));
      setInformationIntent(rasaData);
      setFollowingIntent(initalMessage[1]);

      if (initalMessage[2]) {
        setPassEntities(false);
      }

      setLastMessage(initalMessage[0]);
      setButtonClicked(true);
      setMessage("");
    } catch (error) {
      console.log(error);
      setResponse("Mi dispiace, c'è stato un errore");
    }
  };

  //G.M. PICCOLI SPA THE PERLA COMPANY
  // informationIntent.entities[0].value

  console.log(informationIntent);

  const [buttonClicked, setButtonClicked] = useState(false);

  const [systemResponse, setSystemResponse] = useState("");
  useEffect(() => {
    if (!buttonClicked) {
      return; // L'hook useEffect non viene eseguito se l'utente non ha ancora interagito con l'applicazione
    }

    const data =
      informationIntent === ""
        ? ""
        : {
            sender: "user",
            context: "main",
            entities:
              informationIntent.entities.length > 0 && passEntities
                ? informationIntent.entities.map((entity) => {
                    return {
                      "name": entity.entity,
                      "confidence": entity.confidence_entity,
                      "value": entity.value,
                    };
                  })
                : [],
            message: informationIntent.text || "ciao",
            intent:
              followingIntent === undefined ||
              !followingIntent ||
              followingIntent === "" ||
              followingIntent === null
                ? { name: informationIntent.intent.name }
                : { name: followingIntent },

            intent_ranking: {
              confidence: informationIntent.intent_ranking[0].confidence,
            },
            sessionId: sessionId || "",
          };

    //https://demo.fieramilano.fulgeas.com/connector.php

    //http://localhost:8080/connector.php

    console.log("sono ioooo", data);
    axios
      .post("https://demo.fieramilano.fulgeas.com/connector.php", data)
      .then((response) => {
        setButtonClicked(false);
        console.log(response);
        const chatMessage = {
          userMessage: lastMessage,
          systemResponse: response.data,
        };

        setSystemResponse(response.data);

        if (response.data[0].following_intent !== undefined) {
          setFollowingIntent(response.data[0].following_intent);
        } else {
          setFollowingIntent(null);
          console.log(chatHistory);
        }
        setChatHistory((prevChatHistory) => [...prevChatHistory, chatMessage]);

        setPassEntities(true);
      })

      .catch((error) => {
        console.log(error);
      });
  }, [buttonClicked]);

  const modalRef = useRef(null);
  const [inViewRef, inView] = useInView({ threshold: 1 });

  // https://cognitivesearch.fulgeas.com/api/get-intent

  // useEffect(() => {
  //   const cognitive_url = "https://cognitivesearch.fulgeas.com/api/get-intent";
  //   const config_cognitive = {
  //     project_id: "abcdef",
  //     intent_name: "categories_search",
  //   };

  //   axios
  //     .post(cognitive_url, config_cognitive)
  //     .then((res) => console.log(res))
  //     .catch((err) => console.log(err));
  // }, []);

  console.log(buttonClicked);
  return (
    <>
      <div className='bg-wall-blue container-header'>
        <NavbarRegistration></NavbarRegistration>
        <div
          className='text-center text-white d-grid'
          style={{ height: "500px" }}
        >
          <div style={{ marginTop: "100px" }}>
            <h3>TUTTOFOOD</h3>
            <h6 className='my-3'>
              <b>Benvenuti nella piattaforma digitale di TUTTOFOOD</b>
            </h6>
            <div className='d-flex justify-content-center align-items-center'>
              <div className='form-group has-search position-relative'>
                <span className='bi bi-search form-control-feedback p-0'></span>
                <input
                  type='text'
                  className='form-control-home search-bar'
                  placeholder='Cerca tra espositori, prodotti, settori e storie'
                />
              </div>
            </div>
            <div>
              <a
                href='#'
                className='btn text-black btn-ai mt-5 shadow py-3'
                data-bs-toggle='modal'
                data-bs-target='#staticBackdrop'
              >
                <b>TUTTOFOOD VIRTUAL ASSISTANT</b>
              </a>

              {/* Modale Chat */}

              <div
                className='modal fade'
                id='staticBackdrop'
                data-bs-backdrop='static'
                data-bs-keyboard='false'
                tabIndex='-1'
                aria-labelledby='staticBackdropLabel'
                aria-hidden='true'
              >
                <div className='modal-dialog position-relative p-3'>
                  <div className='modal-content position-relative borderModal'>
                    <div className='modal-header d-flex justify-content-between px-4'>
                      <h5 className='modal-title'>TUTTOFOOD ASSISTANT</h5>
                      <button
                        type='button'
                        className='btn-close m-0'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      ></button>
                    </div>
                    <div className='modal-body text-dark text-start p-0'>
                      <div className='row d-flex align-items-start p-0 m-0 message-chat mt-3 mb-2'>
                        <div className='col-1 d-flex justify-content-center p-0'>
                          <div className='rounded-circle bg-red icon-bot'>
                            <i className='bi bi-chat-dots-fill'></i>
                          </div>
                        </div>
                        <div className='col-11 p-0'>
                          <MacchinaDaScrivere
                            text={
                              "Ciao, sono l'assistente virtuale di Tuttofood! Se hai bisogno di informazioni riguardo all'evento o hai bisogno di informazioni di catalogo sarò felice di aiutarti!"
                            }
                          ></MacchinaDaScrivere>
                          <div className='d-flex align-items-center gap-2'>
                            {/* Orari apertura */}
                            <button
                              className={`btn buttonScelta mt-2 ${
                                clicked ? "disabled" : ""
                              }`}
                              type='button'
                              value={
                                "quali sono gli orari di apertura e chiusura?;fair_hour"
                              }
                              onClick={(event) =>
                                ButtonNavigation(event.target.value)
                              }
                            >
                              &#x23F1;&#xFE0F; Orari della fiera
                            </button>
                            {/* Saluta */}
                            <button
                              className={`btn buttonScelta mt-2 ${
                                clicked ? "disabled" : ""
                              }`}
                              type='button'
                              value={"Quanto costa il biglietto?;ticket_price"}
                              onClick={(event) =>
                                ButtonNavigation(event.target.value)
                              }
                            >
                              &#x1F39F;&#xFE0F; Prezzi biglietteria
                            </button>
                            {/* Saluta */}
                            <button
                              className={`btn buttonScelta mt-2 ${
                                clicked ? "disabled" : ""
                              }`}
                              type='button'
                              value={"Dove posso parcheggiare?;info_parcheggi"}
                              onClick={(event) =>
                                ButtonNavigation(event.target.value)
                              }
                            >
                              &#127359; Parcheggi area fiera
                            </button>
                            {/* "dammi informazioni sull'espositore ELISIR
                            INNOVATION DRINK SRL" */}
                          </div>
                        </div>
                      </div>
                      {chatHistory.map((row, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className='row bg-grey-message d-flex align-items-center p-0 m-0 message-chat'>
                              <div className='col-1 d-flex justify-content-center p-0'>
                                <div className='rounded-circle bg-light-blue icon-bot'>
                                  <i className='bi bi-search p-0'></i>
                                </div>
                              </div>
                              <div className='col-11 text-dark text-start p-0'>
                                {row.userMessage}
                              </div>
                            </div>

                            <>
                              <div className='text-dark text-start p-0 bg-white '>
                                <div className='row d-flex align-items-center p-0 m-0 message-chat chat'>
                                  <div className='col-12 d-flex justify-content-center p-0 mt-3'>
                                    <div className='col-1 d-flex justify-content-center'>
                                      <div className='rounded-circle bg-red icon-bot'>
                                        <i className='bi bi-chat-dots-fill'></i>
                                      </div>
                                    </div>
                                    <div className='col-11 mb-4 bot-response'>
                                      <MacchinaDaScrivere
                                        text={""}
                                        lastMessage={lastMessage}
                                      />
                                      {row.systemResponse[0].bot_text.includes(
                                        "[exhibitor_list]"
                                      ) ? (
                                        React.createElement(
                                          "span",
                                          null,
                                          row.systemResponse[0].bot_text
                                            .split("[exhibitor_list]")
                                            .map((text, index) => (
                                              <React.Fragment key={index}>
                                                {/* <p
                                                dangerouslySetInnerHTML={{
                                                  __html: text,
                                                }}
                                              ></p> */}

                                                <MacchinaDaScrivere
                                                  text={text}
                                                  lastMessage={lastMessage}
                                                />
                                                {index !==
                                                row.systemResponse[0].bot_text.split(
                                                  "[exhibitor_list]"
                                                ).length -
                                                  1 ? (
                                                  <ExhibitorsList
                                                    listItem={
                                                      row.systemResponse[0]
                                                    }
                                                    followingIntent={
                                                      followingIntent
                                                    }
                                                    setFollowingIntent={
                                                      setFollowingIntent
                                                    }
                                                    setChatHistory={
                                                      setChatHistory
                                                    }
                                                    ButtonNavigation={
                                                      ButtonNavigation
                                                    }
                                                  />
                                                ) : null}
                                              </React.Fragment>
                                            ))
                                        )
                                      ) : row.systemResponse[0].bot_text.includes(
                                          "[exhibitor_detail]"
                                        ) ? (
                                        React.createElement(
                                          "span",
                                          null,
                                          row.systemResponse[0].bot_text
                                            .split("[exhibitor_detail]")
                                            .map((text, index) => (
                                              <React.Fragment key={index}>
                                                <MacchinaDaScrivere
                                                  text={text}
                                                  lastMessage={lastMessage}
                                                />
                                                {index !==
                                                row.systemResponse[0].bot_text.split(
                                                  "[exhibitor_detail]"
                                                ).length -
                                                  1 ? (
                                                  <ExhibitorDetail
                                                    listItem={
                                                      row.systemResponse[0]
                                                    }
                                                    followingIntent={
                                                      followingIntent
                                                    }
                                                    setFollowingIntent={
                                                      setFollowingIntent
                                                    }
                                                    setChatHistory={
                                                      setChatHistory
                                                    }
                                                    ButtonNavigation={
                                                      ButtonNavigation
                                                    }
                                                  />
                                                ) : null}
                                              </React.Fragment>
                                            ))
                                        )
                                      ) : (
                                        <MacchinaDaScrivere
                                          text={row.systemResponse[0].bot_text}
                                          ButtonNavigation={ButtonNavigation}
                                          lastMessage={lastMessage}
                                        />
                                      )}
                                    </div>
                                  </div>

                                  {/* Risposta del bot  */}
                                </div>
                              </div>
                            </>
                          </React.Fragment>
                        );
                      })}
                      <div className='last-div py-3' ref={scrollRef}></div>
                    </div>

                    <div className='divisore-input'></div>
                    <div className='modal-footer d-flex justify-content-center position-sticky bottom-0'>
                      {/* <div className='chat-footer position-relative mt-0'>
                        <input
                          type='text'
                          className='rounded-pill chat-input'
                          placeholder='Cerca tra espositori, prodotti, espositori e storie'
                          value={message}
                          onChange={(event) => setMessage(event.target.value)}
                          onKeyDown={handleKeyDown}
                        />
                        <div className='chat-send-button'>
                          <a
                            className='btn btn-dark rounded-circle'
                            type='button'
                            onClick={handleSendMessage}
                          >
                            {" "}
                            <i className='bi bi-search p-0'></i>
                          </a>
                        </div>
                      </div> */}

                      <div className='chat-footer d-flex justify-content-center align-items-center'>
                        <div className='form-group has-search position-relative'>
                          <a
                            className=' form-control-feedback p-0 pe-auto icon-search-container'
                            onClick={handleSendMessage}
                          >
                            <i className='bi bi-search bg-light-blue rounded-circle text-white search-icon '></i>
                          </a>
                          <input
                            type='text'
                            className='form-control search-bar rounded-pill'
                            placeholder='Cerca tra espositori, prodotti e storie'
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='main-content'>
        <CategoriesHome />
      </div>
    </>
  );
};

export default Homepage;
