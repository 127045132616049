import React, { useState, useEffect } from "react";
import MacchinaDaScrivere from "./MacchinaDaScrivere";

const ExhibitorDetail = ({ listItem, mostraInfo, setMostraInfo }) => {
  // nascondi gli elementi all'inizio

  console.log(listItem);
  return (
    <div className='col-11 p-0 py-2'>
      <div className='row'>
        <div className='col-12 mb-3'>
          {listItem.description ? listItem.description : ""}
        </div>
        <div className='col-12 d-flex align-items-center gap-3'>
          {listItem.logo !== "" ||
          listItem.logo !== undefined ||
          listItem.logo !== null ? (
            <div
              className='cont-logo-azienda fade-element'
              style={{
                background: `url(https://d2rv524b8x0nx6.cloudfront.net/${listItem.logo}) center center / cover`,
              }}
            ></div>
          ) : (
            <div className='cont-logo-azienda fade-element'></div>
          )}
          <a href='' className='btn btn-chatbot fade-element'>
            Vai alla scheda completa
          </a>
          <a href='' className='btn btn-chatbot fade-element'>
            Prenota un appuntamento
          </a>
        </div>
        {/* I prodotti */}
        {listItem.products.length === 0 ? (
          ""
        ) : (
          <div className='col-12 products-section'>
            <div className='row d-flex align-items-center my-4 justify-content-between '>
              <div className='col-auto'>
                <h4 className='text-uppercase m-0 fs-5'>
                  <b className=''>Prodotti</b>
                </h4>
              </div>
              <div className='col d-flex justify-content-center'>
                <div className='dotted-hr'></div>
              </div>
              <div className='col-auto'>
                <a className='' href='#'>
                  Vedi tutti i prodotti
                </a>
              </div>
            </div>
            <div className='row'>
              {listItem.products.map((product, index) => {
                return (
                  <div className='col-2' key={index}>
                    <div className='card my-2' style={{ height: "250px" }}>
                      <div className='card-body p-0 d-flex justify-content-center align-items-center'>
                        {product.cover_picture !== null ? (
                          <img
                            src={`https://d2rv524b8x0nx6.cloudfront.net/${product.cover_picture}`}
                            className='h-100 w-auto'
                            alt=''
                          />
                        ) : (
                          <i className='bi bi-card-image display-3 text-secondary '></i>
                        )}
                      </div>
                      <div className='card-footer'>
                        <h6>
                          <b>
                            {product.name !== null
                              ? product.name.length >= 30
                                ? product.name.substr(0, 30) + " ..."
                                : product.name
                              : ""}
                          </b>
                        </h6>

                        <h6>
                          <b>
                            {" "}
                            {product.price === null || product.price === ""
                              ? ""
                              : "€" + product.price + ",00"}
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {/* Le storie */}
        {listItem.stories.length === 0 ? (
          ""
        ) : (
          <div className='col-12 mt-4 mb-4 stories-section'>
            <div className='row d-flex align-items-center my-4 justify-content-between'>
              <div className='col-auto'>
                <h4 className='text-uppercase m-0 fs-6'>
                  <b>storie</b>
                </h4>
              </div>
              <div className='col d-flex justify-content-center'>
                <div className='dotted-hr'></div>
              </div>
              <div className='col-auto'>
                <a className='' href='#'>
                  Vedi tutte le storie
                </a>
              </div>
            </div>
            <div className='row'>
              {listItem.stories.map((story, index) => {
                return (
                  <div className='col-2' key={index}>
                    <div className='card' style={{ height: "250px" }}>
                      <div className='card-body p-0'>
                        <img
                          src={`https://d2rv524b8x0nx6.cloudfront.net/${story.cover_picture}`}
                          className='h-100 w-auto'
                          alt=''
                        />
                      </div>
                      <div className='card-footer'>
                        <h6>
                          <b>
                            {story.body !== null ? (
                              story.body.length >= 30 ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: story.body.substr(0, 30) + " ...",
                                  }}
                                  className='m-0'
                                ></span>
                              ) : (
                                <span
                                  className='m-0'
                                  dangerouslySetInnerHTML={{
                                    __html: story.body,
                                  }}
                                ></span>
                              )
                            ) : (
                              ""
                            )}
                          </b>
                        </h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExhibitorDetail;
