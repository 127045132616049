import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import axios from "axios";
import ExhibitorsList from "./ExhibitorsList";
import ExhibitorDetail from "./ExhibitorDetail";
import MacchinaDaScrivere from "./MacchinaDaScrivere";

const ModalExhibitor = () => {
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [response, setResponse] = useState("");
  const [jsonRasa, setJsonRasa] = useState("");
  const [informationIntent, setInformationIntent] = useState("");
  const [followingIntent, setFollowingIntent] = useState(null);
  const [passEntities, setPassEntities] = useState(true);

  //Classificazione intent

  // Inizializza lo stato iniziale della sessione.
  const [sessionId, setSessionId] = useState(null);

  const err502message =
    "Ci scusiamo per l'inconveniente. Il server sta attualmente riscontrando un errore 502 e stiamo lavorando per risolvere il problema il prima possibile. Ti preghiamo di riprovare più tardi. Grazie per la tua pazienza e comprensione.";

  // Genera un nuovo ID di sessione quando l'utente accede alla chatbot per la prima volta.
  useEffect(() => {
    if (!sessionId) {
      const newSessionId = generateSessionId();
      setSessionId(newSessionId);
      localStorage.setItem("sessionId", newSessionId);
    }
  }, [sessionId]);

  // Rimuovi l'ID di sessione dal localStorage quando l'utente effettua il refresh della pagina.
  function handleBeforeUnload() {
    localStorage.removeItem("sessionId");
  }

  const scrollRef = useRef(null);

  useEffect(() => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  }, [informationIntent]);

  // Registra l'evento beforeunload per gestire il refresh della pagina.
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Genera un nuovo ID di sessione.
  function generateSessionId() {
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 100000);
    return `${timestamp}-${random}`;
  }

  const [clicked, setIsClicked] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setIsClicked(true);
      handleSendMessage();
    }
  };

  const [lastMessage, setLastMessage] = useState("");
  const handleSendMessage = async () => {
    setIsClicked(false);
    if (message.trim() === "") return;

    // https://engine.cognitivesearch.fulgeas.com/model/parse

    // http://localhost:5005/model/parse

    //https://cognitivesearch.fulgeas.com/api/get-model/abcdef

    try {
      const rasaResponse = await fetch(
        "https://engine.cognitivesearch.fulgeas.com/model/parse",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ text: message }),
        }
      );

      if (rasaResponse.status === 502) {
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          {
            userMessage: message,
            systemResponse: [
              {
                bot_text: err502message,
              },
            ],
          },
        ]);
        setMessage("");
      }

      const rasaData = await rasaResponse.json();
      setJsonRasa(JSON.stringify(rasaData, null, 2));
      setInformationIntent(rasaData);

      setLastMessage(message);
      setMessage("");
      setButtonClicked(true);
    } catch (error) {
      console.log(error);
      setResponse("Mi dispiace, c'è stato un errore");
    }
  };

  const ButtonNavigation = async (message) => {
    let initalMessage = message.split(";");

    console.log(initalMessage[0]);
    if (message.trim() === "") return;

    // https://engine.cognitivesearch.fulgeas.com/model/parse

    // http://localhost:5005/model/parse

    //https://cognitivesearch.fulgeas.com/api/get-model/abcdef

    console.log(systemResponse);

    try {
      const rasaResponse = await fetch(
        "https://engine.cognitivesearch.fulgeas.com/model/parse",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            text: initalMessage[0],
          }),
        }
      );

      if (rasaResponse.status === 502) {
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          {
            userMessage: initalMessage[0],
            systemResponse: [
              {
                bot_text: err502message,
              },
            ],
          },
        ]);
        setMessage("");
      }

      const rasaData = await rasaResponse.json();
      setJsonRasa(JSON.stringify(rasaData, null, 2));
      setInformationIntent(rasaData);
      setFollowingIntent(initalMessage[1]);

      if (initalMessage[2]) {
        setPassEntities(false);
      }

      setLastMessage(initalMessage[0]);
      setButtonClicked(true);
      setMessage("");
    } catch (error) {
      console.log(error);
      setResponse("Mi dispiace, c'è stato un errore");
    }
  };

  //G.M. PICCOLI SPA THE PERLA COMPANY
  // informationIntent.entities[0].value

  console.log(informationIntent);

  const [buttonClicked, setButtonClicked] = useState(false);

  const [systemResponse, setSystemResponse] = useState("");
  useEffect(() => {
    if (!buttonClicked) {
      return; // L'hook useEffect non viene eseguito se l'utente non ha ancora interagito con l'applicazione
    }

    const data =
      informationIntent === ""
        ? ""
        : {
            sender: "user",
            context: "exhibitor",
            entities:
              informationIntent.entities.length > 0 && passEntities
                ? informationIntent.entities.map((entity) => {
                    return {
                      "name": entity.entity,
                      "confidence": entity.confidence_entity,
                      "value": entity.value,
                    };
                  })
                : [],
            message: informationIntent.text || "",
            intent:
              followingIntent === undefined ||
              !followingIntent ||
              followingIntent === "" ||
              followingIntent === null
                ? { name: informationIntent.intent.name }
                : { name: followingIntent },

            intent_ranking: {
              confidence: informationIntent.intent_ranking[0].confidence,
            },
            sessionId: sessionId || "",
          };

    //https://demo.fieramilano.fulgeas.com/connector.php

    //http://localhost:8080/connector.php

    console.log("sono ioooo", data);
    axios
      .post("https://demo.fieramilano.fulgeas.com/connector.php", data)
      .then((response) => {
        setButtonClicked(false);
        console.log(response);
        const chatMessage = {
          userMessage: lastMessage,
          systemResponse: response.data,
        };

        setSystemResponse(response.data);

        if (response.data[0].following_intent !== undefined) {
          setFollowingIntent(response.data[0].following_intent);
        } else {
          setFollowingIntent(null);
          console.log(chatHistory);
        }
        setChatHistory((prevChatHistory) => [...prevChatHistory, chatMessage]);

        setPassEntities(true);
      })

      .catch((error) => {
        console.log(error);
      });
  }, [buttonClicked]);

  console.log(chatHistory);
  return (
    <div
      className='modal fade '
      id='staticBackdrop'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
    >
      <div
        className='modal-dialog position-relative p-3 d-flex justify-content-center position-absolute'
        style={{ right: "30px", top: "10px" }}
      >
        <div className='modal-content position-relative modal-exhibitor'>
          <div className='modal-header'>
            <h5
              className='modal-title'
              style={{ fontSize: "15px" }}
              id='staticBackdropLabel'
            >
              ELISIR INNOVATION DRINK SRL ASSISTANT
            </h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body text-dark text-start p-0 px-2'>
            <div className='row d-flex align-items-start p-0 m-0 mt-3 mb-4'>
              <div className='col-1 d-flex justify-content-center p-0'>
                <div className='rounded-circle bg-red icon-bot p-0 me-0'>
                  <i className='bi bi-chat-dots-fill'></i>
                </div>
              </div>

              <div
                className='col-10 d-grid justify-content-start align-items-start pe-2'
                style={{ fontSize: "14px", hyphens: "auto" }}
              >
                <MacchinaDaScrivere
                  text={
                    "Ciao, sono l'assistente virtuale di ELISIR INNOVATION DRINK SRL, come posso aiutarti?"
                  }
                ></MacchinaDaScrivere>
                <div className='d-flex align-items-center gap-2'>
                  {/* Orari apertura */}
                  <button
                    className={`btn buttonScelta mt-2 ${
                      clicked ? "disabled" : ""
                    }`}
                    type='button'
                    value={
                      "è possibile prenotare un appuntamento?;exhibitor_reservation"
                    }
                    style={{ fontSize: "14px" }}
                    onClick={(event) => ButtonNavigation(event.target.value)}
                  >
                    &#128197; Prenotazione
                  </button>

                  {/* Saluta */}
                  <button
                    className={`btn buttonScelta mt-2 ${
                      clicked ? "disabled" : ""
                    }`}
                    type='button'
                    value={"cos'è TUTTOFOOD?;presentazione_fiera"}
                    onClick={(event) => ButtonNavigation(event.target.value)}
                    style={{ fontSize: "14px" }}
                  >
                    &#x2139;&#xfe0f; Informazioni
                  </button>
                </div>
              </div>
              <div className='col-1 d-flex justify-content-center p-0'></div>
            </div>
            {chatHistory.map((row, index) => {
              return (
                <React.Fragment key={index}>
                  <div className='row d-flex align-items-start p-0 m-0 my-3'>
                    <div className='col-1 d-flex justify-content-center p-0 m-0'></div>
                    <div className='col-10 text-dark text-end p-0 d-flex justify-content-end '>
                      <div
                        className='bg-grey-message box-message py-2 px-3 d-flex align-items-center justify-content-end me-2'
                        style={{ hyphens: "auto", fontSize: "14px" }}
                      >
                        {row.userMessage}
                      </div>
                    </div>
                    <div className='col-1 d-flex justify-content-center p-0 m-0'>
                      <div className='rounded-circle bg-light-blue icon-bot p-0'>
                        <i className='bi bi-search p-0'></i>
                      </div>
                    </div>
                  </div>

                  <>
                    <div className='text-dark text-start p-0 bg-white '>
                      <div className='row d-flex align-items-start p-0 m-0 chat my-3 h-auto'>
                        <div className='col-1 d-flex justify-content-center p-0'>
                          <div className='rounded-circle bg-red icon-bot'>
                            <i className='bi bi-chat-dots-fill'></i>
                          </div>
                        </div>
                        <div
                          className='col-10 bot-response pe-0 pe-2'
                          style={{ fontSize: "14px" }}
                        >
                          <MacchinaDaScrivere
                            text={""}
                            lastMessage={lastMessage}
                            chatHistory={chatHistory}
                          />
                          {row.systemResponse[0].bot_text.includes(
                            "[exhibitor_list]"
                          ) ? (
                            React.createElement(
                              "span",
                              null,
                              row.systemResponse[0].bot_text
                                .split("[exhibitor_list]")
                                .map((text, index) => (
                                  <React.Fragment key={index}>
                                    {/* <p
                                                dangerouslySetInnerHTML={{
                                                  __html: text,
                                                }}
                                              ></p> */}

                                    <MacchinaDaScrivere
                                      text={text}
                                      lastMessage={lastMessage}
                                      chatHistory={chatHistory}
                                    />
                                    {index !==
                                    row.systemResponse[0].bot_text.split(
                                      "[exhibitor_list]"
                                    ).length -
                                      1 ? (
                                      <ExhibitorsList
                                        listItem={row.systemResponse[0]}
                                        followingIntent={followingIntent}
                                        setFollowingIntent={setFollowingIntent}
                                        setChatHistory={setChatHistory}
                                        ButtonNavigation={ButtonNavigation}
                                      />
                                    ) : null}
                                  </React.Fragment>
                                ))
                            )
                          ) : row.systemResponse[0].bot_text.includes(
                              "[exhibitor_detail]"
                            ) ? (
                            React.createElement(
                              "span",
                              null,
                              row.systemResponse[0].bot_text
                                .split("[exhibitor_detail]")
                                .map((text, index) => (
                                  <React.Fragment key={index}>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: text,
                                      }}
                                    ></p>
                                    {index !==
                                    row.systemResponse[0].bot_text.split(
                                      "[exhibitor_detail]"
                                    ).length -
                                      1 ? (
                                      <ExhibitorDetail
                                        listItem={row.systemResponse[0]}
                                        followingIntent={followingIntent}
                                        setFollowingIntent={setFollowingIntent}
                                        setChatHistory={setChatHistory}
                                      />
                                    ) : null}
                                  </React.Fragment>
                                ))
                            )
                          ) : (
                            <MacchinaDaScrivere
                              text={row.systemResponse[0].bot_text}
                              ButtonNavigation={ButtonNavigation}
                              lastMessage={lastMessage}
                              chatHistory={chatHistory}
                            />
                          )}
                        </div>
                      </div>

                      {/* Risposta del bot  */}
                    </div>
                  </>
                </React.Fragment>
              );
            })}
            <div className='last-div py-3' ref={scrollRef}></div>
          </div>

          <div className='modal-footer d-flex justify-content-center position-sticky bottom-0'>
            <div className='chat-footer align-items-center'>
              <div className='form-group has-search position-relative'>
                <a
                  className=' form-control-feedback p-0 pe-auto icon-search-container'
                  onClick={handleSendMessage}
                >
                  <i className='bi bi-search bg-light-blue rounded-circle text-white search-icon '></i>
                </a>
                <input
                  type='text'
                  className='form-control-exhibitor search-bar rounded-pill bar-exhibitor w-100'
                  placeholder='Cerca tra espositori, prodotti e storie'
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalExhibitor;
