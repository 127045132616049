import React from "react";
import ScopriEspositori from "../img/scopri-espositori.png";
import tuttofrozen from "../img/tuttofrozen.png";
import tuttoseafood from "../img/tuttoseafood.png";
import tuttopasta from "../img/tuttopasta.png";
import tuttogrocery from "../img/tuttogrocery.png";
import tuttooil from "../img/tuttooil.png";
import tuttodairy from "../img/tuttodairy.png";

const CategoriesHome = () => {
  return (
    <div className='container'>
      <div className='row text-white'>
        <div className='col-12 col-lg-6 category-cont'>
          <div
            className='rounded category mt-4 p-3'
            style={{
              background: `url(${ScopriEspositori}) center center / cover`,
            }}
          >
            <h4>Scopri chi sono gli espositori</h4>
          </div>
        </div>

        <div className='col-12 col-lg-3 category-cont'>
          <div
            className='rounded category mt-4 p-3'
            style={{ background: `url(${tuttofrozen}) center center / cover` }}
          >
            <h4>tuttofrozen</h4>
          </div>
        </div>

        <div className='col-12 col-lg-3 category-cont'>
          <div
            className='rounded category mt-4 p-3'
            style={{ background: `url(${tuttoseafood}) center center / cover` }}
          >
            <h4>tuttoseafood</h4>
          </div>
        </div>

        <div className='col-12 col-lg-3 category-cont'>
          <div
            className='rounded category mt-3 p-3'
            style={{ background: `url(${tuttopasta}) center center / cover` }}
          >
            <h4>tuttopasta</h4>
          </div>
        </div>

        <div className='col-12 col-lg-3 category-cont'>
          <div
            className='rounded category mt-3 p-3'
            style={{ background: `url(${tuttogrocery}) center center / cover` }}
          >
            <h4>tuttogrocery</h4>
          </div>
        </div>

        <div className='col-12 col-lg-3 category-cont'>
          <div
            className='rounded category mt-3 p-3'
            style={{ background: `url(${tuttooil}) center center / cover` }}
          >
            <h4>tuttooil</h4>
          </div>
        </div>

        <div className='col-12 col-lg-3 category-cont'>
          <div
            className='rounded category mt-3 p-3'
            style={{ background: `url(${tuttodairy}) center center / cover` }}
          >
            <h4>tuttodairy</h4>
          </div>
        </div>

        <div className='col-12 col-lg-3 category-cont'>
          <div className='rounded category mt-3 p-3'>
            <h4>tuttomeat</h4>
          </div>
        </div>

        <div className='col-12 col-lg-3 category-cont'>
          <div className='rounded category mt-3 p-3'>
            <h4>tuttohealth</h4>
          </div>
        </div>

        <div className='col-12 col-lg-3 category-cont'>
          <div className='rounded category mt-3 p-3'>
            <h4>tuttofruit</h4>
          </div>
        </div>

        <div className='col-12 col-lg-3 category-cont'>
          <div className='rounded category mt-3 p-3'>
            <h4>tuttodrink</h4>
          </div>
        </div>

        <div className='col-12 col-lg-3 category-cont'>
          <div className='rounded category mt-3 p-3'>
            <h4>tuttosweet</h4>
          </div>
        </div>

        <div className='col-12 col-lg-3 category-cont'>
          <div className='rounded category mt-3 p-3'>
            <h4>tuttowine</h4>
          </div>
        </div>

        <div className='col-12 col-lg-3 category-cont'>
          <div className='rounded category mt-3 p-3'>
            <h4>tuttodigital</h4>
          </div>
        </div>

        <div className='col-12 col-lg-3 category-cont'>
          <div className='rounded category mt-3 p-3'>
            <h4>tuttoworld</h4>
          </div>
        </div>

        <div className='col-12 col-lg-6 category-cont'>
          <div className='rounded category mt-3 p-3'>
            <h4>Eventi</h4>
          </div>
        </div>

        <div className='col-12 col-lg-6 category-cont'>
          <div className='rounded category mt-3 p-3'>
            <h4>Better Future Award</h4>
          </div>
        </div>

        <div className='col-12 col-lg-6 category-cont'>
          <div className='rounded category mt-3 p-3'>
            <h4>Sei un Hosted Buyer? Programma ora la tua agenda</h4>
          </div>
        </div>

        <div className='col-12 col-lg-6'>
          <div className='rounded category mt-3 p-3'>
            <h4>Biglietteria</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesHome;
