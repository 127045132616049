import React from "react";
import ElisirLogo from "../img/ElisirLogo.png";
import { Link } from "react-router-dom";

const ScrollExhibitors = () => {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='cardEx rounded '>
              <div className='row d-flex align-items-center p-2'>
                <div className='col-auto'>
                  <img
                    src={`${ElisirLogo}`}
                    alt=''
                    className='logo-exhibitor'
                  />
                </div>
                <div className='col-auto w-auto d-grid align-items-center'>
                  <h5 className='m-0'>
                    <Link
                      className='text-decoration-none text-dark'
                      to={"/exhibitor/1"}
                    >
                      ELISIR INNOVATION DRINK SRL
                    </Link>
                  </h5>
                  <p className='m-0'>Floridia (SR) / Italia</p>
                </div>
                <div className='col d-flex w-auto justify-content-end align-self-start pe-3 pt-2'>
                  <p className='badge bg-secondary'>Pad. 3P - Stand U21</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScrollExhibitors;
