import React from "react";

const FilterExhibitors = () => {
  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <h4>Filtri</h4>
        </div>
        <div className='col-12'>
          <input type='text' placeholder='Cerca espositore' className='w-100' />
        </div>
        <div className='col-12 d-flex align-items-center my-3 mt-4'>
          <p className='w-100 m-0'>
            <b>Settori di interesse</b>
          </p>
          <p className='m-0'>
            <b>+</b>
          </p>
        </div>

        <hr className='text-secondary p-0 m-0' />

        <div className='col-12 d-flex align-items-center my-3'>
          <p className='w-100 m-0'>
            <b>Padiglione</b>
          </p>
          <p className='m-0'>
            <b>+</b>
          </p>
        </div>
        <hr className='text-secondary p-0 m-0' />

        <div className='col-12 d-flex align-items-center my-3'>
          <p className='w-100 m-0'>
            <b>Nazione</b>
          </p>
          <p className='m-0'>
            <b>+</b>
          </p>
        </div>
        <hr className='text-secondary p-0 m-0' />

        <div className='col-12 d-flex align-items-center my-3'>
          <p className='w-100 m-0'>
            <b>A-Z</b>
          </p>
          <p className='m-0'>
            <b>+</b>
          </p>
        </div>
        <hr className='text-secondary p-0 m-0' />
      </div>
    </>
  );
};

export default FilterExhibitors;
