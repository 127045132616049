import React from "react";
import NavbarExhibitors from "./NavbarExhibitors";
import { Link } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import ElisirLogo from "../img/ElisirLogo.png";
import ElisirBanner from "../img/ElisirBanner.png";
import ModalExhibitor from "./ModalExhibitor";
import ExhibitorAssistantLogo from "../img/bot-ai.png";

const ExhibitorSingle = () => {
  return (
    <>
      <NavbarExhibitors></NavbarExhibitors>
      <div className='container'>
        <div className='row'>
          <div className='col-12 d-flex align-items-center gap-2 '>
            <Link
              className='text-decoration-none text-dark d-flex align-items-center'
              to={"/"}
            >
              <AiOutlineHome />
            </Link>
            /
            <Link
              className='text-decoration-none text-dark d-flex align-items-center gap-1'
              to={"/exhibitors"}
            >
              Espositori
            </Link>
            /
            <Link
              className='text-decoration-none text-dark d-flex align-items-center gap-1'
              to={"/exhibitors"}
            >
              ELISIR INNOVATION DRINK SRL
            </Link>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div
              className='banner mt-3'
              style={{
                background: `url(${ElisirBanner}) center center/contain no-repeat`,
              }}
            ></div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-2'>
            <div className='riga-info position-relative'>
              <img
                src={`${ElisirLogo}`}
                alt=''
                className='logo-exhibitor-single'
              />
            </div>
          </div>

          <div className='col'>
            <h3>ELISIR INNOVATION DRINK SRL</h3>
            <h6 className='text-secondary'>FLORIDIA (SR) / Italia</h6>
            <h6 className='badge bg-secondary p-2'>Pad. 3P - Stand U21</h6>
          </div>

          <div
            className='col d-flex align-self-center justify-content-end position-fixed position-relative'
            style={{ bottom: "30px", right: "20px", zIndex: "3" }}
          >
            <button
              href='#'
              className='btn text-black mt-5 shadow py-3 rounded-circle button-ai-exhibitor position-relative'
              data-bs-toggle='modal'
              data-bs-target='#staticBackdrop'
            >
              <img
                src={`${ExhibitorAssistantLogo}`}
                alt=''
                className='assistant-logo'
              />
              <div
                className='position-absolute bg-white message-hello d-flex align-items-center p-1'
                style={{ top: 0, left: "-160px" }}
              >
                <p className='m-0'>Ciao, serve una mano?</p>
              </div>
            </button>
          </div>

          <div className='col-12 d-flex align-items-center gap-2 mt-5 mb-3'>
            <a
              href=''
              className='text-decoration-none text-dark bg-secondary link-info-exhibitor'
            >
              Overview
            </a>
            <a
              href=''
              className='text-decoration-none text-dark bg-secondary link-info-exhibitor'
            >
              Settori
            </a>
            <a
              href=''
              className='text-decoration-none text-dark bg-secondary link-info-exhibitor'
            >
              Prodotti
            </a>
            <a
              href=''
              className='text-decoration-none text-dark bg-secondary link-info-exhibitor'
            >
              Storie
            </a>
            <a
              href=''
              className='text-decoration-none text-dark bg-secondary link-info-exhibitor'
            >
              Documenti
            </a>
            <a
              href=''
              className='text-decoration-none text-dark bg-secondary link-info-exhibitor'
            >
              Social
            </a>
          </div>
          <hr />
        </div>
      </div>
      <ModalExhibitor />
    </>
  );
};

export default ExhibitorSingle;
