import React from "react";

const ExhibitorsList = ({
  listItem,
  followingIntent,
  setFollowingIntent,
  setChatHistory,
  ButtonNavigation,
}) => {
  console.log(listItem);

  return (
    <>
      {listItem.results.map((result, index) => {
        return (
          <div
            className='row fade-element p-0 m-0 position-relative exhibitor-container'
            key={index}
            alt={result.name}
          >
            <div className='col-12 d-flex align-items-center gap-3 fade-element ps-0'>
              {result.logo !== null ? (
                <div
                  className='cont-logo-azienda d-flex justify-content-center'
                  style={{
                    background: `url(https://d2rv524b8x0nx6.cloudfront.net/${result.logo}) center center / contain no-repeat`,
                  }}
                ></div>
              ) : (
                <div className='cont-logo-azienda d-flex justify-content-center align-items-center fs-4'>
                  {result.name.charAt(0)}
                </div>
              )}

              <div className='row fade-element '>
                <div className='m-0'>
                  <p className='m-0'>
                    <b className='name-exhibitor'>{result.name}</b>
                  </p>
                </div>
                <div className='m-0'>
                  <p className='m-0'>
                    {result.city === "" ? "" : result.city}{" "}
                    {result.city_code === null ? "" : `(${result.city_code})`} /{" "}
                    {result.country_name}
                  </p>
                </div>
              </div>
            </div>
            <a
              onClick={() =>
                ButtonNavigation(`${result.name};ask_again_exhibitor_name;no`)
              }
              className='stretched-link pe-auto'
              style={{ cursor: "pointer" }}
            ></a>
          </div>
        );
      })}

      {/* <div className='py-3'>
        {followingIntent === undefined ||
        !followingIntent ||
        followingIntent === null ? (
          ""
        ) : (
          <a
            className='fade-element btn btn-outline-danger'
            type='button'
            onClick={FinisciLoop}
          >
            X Non mi interessa più
          </a>
        )}
      </div> */}
    </>
  );
};

export default ExhibitorsList;
