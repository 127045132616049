import React from "react";
import NavbarExhibitors from "./NavbarExhibitors";
import { AiOutlineHome } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { Link } from "react-router-dom";
import FilterExhibitors from "./FilterExhibitors";
import ScrollExhibitors from "./ScrollExhibitors";

const Exhibitors = () => {
  return (
    <>
      <NavbarExhibitors></NavbarExhibitors>
      <div className='container'>
        <div className='row'>
          <div className='col-12 d-flex align-items-center gap-2 '>
            <Link
              className='text-decoration-none text-dark d-flex align-items-center'
              to={"/"}
            >
              <AiOutlineHome />
            </Link>
            /
            <Link
              className='text-decoration-none text-dark d-flex align-items-center gap-1'
              to={"/exhibitors"}
            >
              <CgProfile /> Espositori
            </Link>
          </div>
          <div className='col-12'>
            <h2 className='my-3'>
              <b>Espositori di TUTTOFOOD</b>
            </h2>
          </div>
        </div>
        <div className='row my-4'>
          <div className='col-2'>
            <FilterExhibitors></FilterExhibitors>
          </div>
          <div className='col-10'>
            <ScrollExhibitors></ScrollExhibitors>
          </div>
        </div>
      </div>
    </>
  );
};

export default Exhibitors;
